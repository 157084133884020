
import { importSync as i } from '@embroider/macros';
let d = window.define;
d("ember-tracked-storage-polyfill/index", function(){ return i("ember-tracked-storage-polyfill/index");});
d("@embroider/macros/es-compat2", function(){ return i("@embroider/macros/es-compat2");});
d("@embroider/macros/runtime", function(){ return i("@embroider/macros/runtime");});
d("@ember/legacy-built-in-components/components/_has-dom", function(){ return i("@ember/legacy-built-in-components/components/_has-dom");});
d("@ember/legacy-built-in-components/components/_internals", function(){ return i("@ember/legacy-built-in-components/components/_internals");});
d("@ember/legacy-built-in-components/components/checkbox", function(){ return i("@ember/legacy-built-in-components/components/checkbox");});
d("@ember/legacy-built-in-components/components/link-to", function(){ return i("@ember/legacy-built-in-components/components/link-to");});
d("@ember/legacy-built-in-components/components/text-field", function(){ return i("@ember/legacy-built-in-components/components/text-field");});
d("@ember/legacy-built-in-components/components/textarea", function(){ return i("@ember/legacy-built-in-components/components/textarea");});
d("@ember/legacy-built-in-components/index", function(){ return i("@ember/legacy-built-in-components/index");});
d("@ember/legacy-built-in-components/mixins/_target_action_support", function(){ return i("@ember/legacy-built-in-components/mixins/_target_action_support");});
d("@ember/legacy-built-in-components/mixins/text-support", function(){ return i("@ember/legacy-built-in-components/mixins/text-support");});
d("@ember/legacy-built-in-components/templates/empty", function(){ return i("@ember/legacy-built-in-components/templates/empty.hbs");});
d("@ember/legacy-built-in-components/components/checkbox", function(){ return i("@ember/legacy-built-in-components/components/checkbox");});
d("@ember/legacy-built-in-components/components/link-to", function(){ return i("@ember/legacy-built-in-components/components/link-to");});
d("@ember/render-modifiers/modifiers/did-insert", function(){ return i("@ember/render-modifiers/modifiers/did-insert");});
d("@ember/render-modifiers/modifiers/did-update", function(){ return i("@ember/render-modifiers/modifiers/did-update");});
d("@ember/render-modifiers/modifiers/will-destroy", function(){ return i("@ember/render-modifiers/modifiers/will-destroy");});
d("ember-cached-decorator-polyfill/index", function(){ return i("ember-cached-decorator-polyfill/index");});
d("@glimmer/component/-private/base-component-manager", function(){ return i("@glimmer/component/-private/base-component-manager");});
d("@glimmer/component/-private/component", function(){ return i("@glimmer/component/-private/component");});
d("@glimmer/component/-private/destroyables", function(){ return i("@glimmer/component/-private/destroyables");});
d("@glimmer/component/-private/ember-component-manager", function(){ return i("@glimmer/component/-private/ember-component-manager");});
d("@glimmer/component/-private/owner", function(){ return i("@glimmer/component/-private/owner");});
d("@glimmer/component/index", function(){ return i("@glimmer/component/index");});
d("discourse-common/config/environment", function(){ return i("discourse-common/config/environment");});
d("discourse-common/deprecation-workflow", function(){ return i("discourse-common/deprecation-workflow");});
d("discourse-common/helpers/base-path", function(){ return i("discourse-common/helpers/base-path");});
d("discourse-common/helpers/base-url", function(){ return i("discourse-common/helpers/base-url");});
d("discourse-common/helpers/bound-i18n", function(){ return i("discourse-common/helpers/bound-i18n");});
d("discourse-common/helpers/component-for-collection", function(){ return i("discourse-common/helpers/component-for-collection");});
d("discourse-common/helpers/component-for-row", function(){ return i("discourse-common/helpers/component-for-row");});
d("discourse-common/helpers/d-icon", function(){ return i("discourse-common/helpers/d-icon");});
d("discourse-common/helpers/fa-icon", function(){ return i("discourse-common/helpers/fa-icon");});
d("discourse-common/helpers/get-url", function(){ return i("discourse-common/helpers/get-url");});
d("discourse-common/helpers/html-safe", function(){ return i("discourse-common/helpers/html-safe");});
d("discourse-common/helpers/i18n-yes-no", function(){ return i("discourse-common/helpers/i18n-yes-no");});
d("discourse-common/helpers/i18n", function(){ return i("discourse-common/helpers/i18n");});
d("discourse-common/lib/attribute-hook", function(){ return i("discourse-common/lib/attribute-hook");});
d("discourse-common/lib/avatar-utils", function(){ return i("discourse-common/lib/avatar-utils");});
d("discourse-common/lib/case-converter", function(){ return i("discourse-common/lib/case-converter");});
d("discourse-common/lib/debounce", function(){ return i("discourse-common/lib/debounce");});
d("discourse-common/lib/deprecated", function(){ return i("discourse-common/lib/deprecated");});
d("discourse-common/lib/discourse-template-map", function(){ return i("discourse-common/lib/discourse-template-map");});
d("discourse-common/lib/dom-from-string", function(){ return i("discourse-common/lib/dom-from-string");});
d("discourse-common/lib/escape", function(){ return i("discourse-common/lib/escape");});
d("discourse-common/lib/get-owner", function(){ return i("discourse-common/lib/get-owner");});
d("discourse-common/lib/get-url", function(){ return i("discourse-common/lib/get-url");});
d("discourse-common/lib/helpers", function(){ return i("discourse-common/lib/helpers");});
d("discourse-common/lib/icon-library", function(){ return i("discourse-common/lib/icon-library");});
d("discourse-common/lib/later", function(){ return i("discourse-common/lib/later");});
d("discourse-common/lib/loader-shim", function(){ return i("discourse-common/lib/loader-shim");});
d("discourse-common/lib/object", function(){ return i("discourse-common/lib/object");});
d("discourse-common/lib/popular-themes", function(){ return i("discourse-common/lib/popular-themes");});
d("discourse-common/lib/raw-handlebars-helpers", function(){ return i("discourse-common/lib/raw-handlebars-helpers");});
d("discourse-common/lib/raw-handlebars", function(){ return i("discourse-common/lib/raw-handlebars");});
d("discourse-common/lib/raw-templates", function(){ return i("discourse-common/lib/raw-templates");});
d("discourse-common/lib/suffix-trie", function(){ return i("discourse-common/lib/suffix-trie");});
d("discourse-common/resolver", function(){ return i("discourse-common/resolver");});
d("discourse-common/utils/decorator-alias", function(){ return i("discourse-common/utils/decorator-alias");});
d("discourse-common/utils/decorators", function(){ return i("discourse-common/utils/decorators");});
d("discourse-common/utils/dom-utils", function(){ return i("discourse-common/utils/dom-utils");});
d("discourse-common/utils/escape-regexp", function(){ return i("discourse-common/utils/escape-regexp");});
d("discourse-common/utils/extract-value", function(){ return i("discourse-common/utils/extract-value");});
d("discourse-common/utils/handle-descriptor", function(){ return i("discourse-common/utils/handle-descriptor");});
d("discourse-common/utils/is-descriptor", function(){ return i("discourse-common/utils/is-descriptor");});
d("discourse-common/utils/macro-alias", function(){ return i("discourse-common/utils/macro-alias");});
d("discourse-common/utils/multi-cache", function(){ return i("discourse-common/utils/multi-cache");});
d("dialog-holder/components/dialog-holder", function(){ return i("dialog-holder/components/dialog-holder");});
d("dialog-holder/services/dialog", function(){ return i("dialog-holder/services/dialog");});
d("pretty-text/allow-lister", function(){ return i("pretty-text/allow-lister");});
d("pretty-text/censored-words", function(){ return i("pretty-text/censored-words");});
d("pretty-text/emoji", function(){ return i("pretty-text/emoji");});
d("pretty-text/emoji/data", function(){ return i("pretty-text/emoji/data");});
d("pretty-text/emoji/version", function(){ return i("pretty-text/emoji/version");});
d("pretty-text/guid", function(){ return i("pretty-text/guid");});
d("pretty-text/inline-oneboxer", function(){ return i("pretty-text/inline-oneboxer");});
d("pretty-text/mentions", function(){ return i("pretty-text/mentions");});
d("pretty-text/oneboxer-cache", function(){ return i("pretty-text/oneboxer-cache");});
d("pretty-text/oneboxer", function(){ return i("pretty-text/oneboxer");});
d("pretty-text/pretty-text", function(){ return i("pretty-text/pretty-text");});
d("pretty-text/sanitizer", function(){ return i("pretty-text/sanitizer");});
d("pretty-text/text-replace", function(){ return i("pretty-text/text-replace");});
d("pretty-text/upload-short-url", function(){ return i("pretty-text/upload-short-url");});
d("discourse-widget-hbs/helpers", function(){ return i("discourse-widget-hbs/helpers");});
d("ember-buffered-proxy/helpers", function(){ return i("ember-buffered-proxy/helpers");});
d("ember-buffered-proxy/mixin", function(){ return i("ember-buffered-proxy/mixin");});
d("ember-buffered-proxy/proxy", function(){ return i("ember-buffered-proxy/proxy");});
d("ember-cli-app-version/initializer-factory", function(){ return i("ember-cli-app-version/initializer-factory");});
d("ember-cli-app-version/utils/regexp", function(){ return i("ember-cli-app-version/utils/regexp");});
d("ember-cli-deprecation-workflow/index", function(){ return i("ember-cli-deprecation-workflow/index");});
d("@ember/-internals/browser-environment/index", function(){ return i("ember-source/@ember/-internals/browser-environment/index");});
d("@ember/-internals/container/index", function(){ return i("ember-source/@ember/-internals/container/index");});
d("@ember/-internals/deprecations/index", function(){ return i("ember-source/@ember/-internals/deprecations/index");});
d("@ember/-internals/environment/index", function(){ return i("ember-source/@ember/-internals/environment/index");});
d("@ember/-internals/error-handling/index", function(){ return i("ember-source/@ember/-internals/error-handling/index");});
d("@ember/-internals/glimmer/index", function(){ return i("ember-source/@ember/-internals/glimmer/index");});
d("@ember/-internals/meta/index", function(){ return i("ember-source/@ember/-internals/meta/index");});
d("@ember/-internals/meta/lib/meta", function(){ return i("ember-source/@ember/-internals/meta/lib/meta");});
d("@ember/-internals/metal/index", function(){ return i("ember-source/@ember/-internals/metal/index");});
d("@ember/-internals/owner/index", function(){ return i("ember-source/@ember/-internals/owner/index");});
d("@ember/-internals/routing/index", function(){ return i("ember-source/@ember/-internals/routing/index");});
d("@ember/-internals/runtime/index", function(){ return i("ember-source/@ember/-internals/runtime/index");});
d("@ember/-internals/runtime/lib/ext/rsvp", function(){ return i("ember-source/@ember/-internals/runtime/lib/ext/rsvp");});
d("@ember/-internals/runtime/lib/mixins/-proxy", function(){ return i("ember-source/@ember/-internals/runtime/lib/mixins/-proxy");});
d("@ember/-internals/runtime/lib/mixins/action_handler", function(){ return i("ember-source/@ember/-internals/runtime/lib/mixins/action_handler");});
d("@ember/-internals/runtime/lib/mixins/comparable", function(){ return i("ember-source/@ember/-internals/runtime/lib/mixins/comparable");});
d("@ember/-internals/runtime/lib/mixins/container_proxy", function(){ return i("ember-source/@ember/-internals/runtime/lib/mixins/container_proxy");});
d("@ember/-internals/runtime/lib/mixins/registry_proxy", function(){ return i("ember-source/@ember/-internals/runtime/lib/mixins/registry_proxy");});
d("@ember/-internals/runtime/lib/mixins/target_action_support", function(){ return i("ember-source/@ember/-internals/runtime/lib/mixins/target_action_support");});
d("@ember/-internals/string/index", function(){ return i("ember-source/@ember/-internals/string/index");});
d("@ember/-internals/utility-types/index", function(){ return i("ember-source/@ember/-internals/utility-types/index");});
d("@ember/-internals/utils/index", function(){ return i("ember-source/@ember/-internals/utils/index");});
d("@ember/-internals/views/index", function(){ return i("ember-source/@ember/-internals/views/index");});
d("@ember/-internals/views/lib/compat/attrs", function(){ return i("ember-source/@ember/-internals/views/lib/compat/attrs");});
d("@ember/-internals/views/lib/compat/fallback-view-registry", function(){ return i("ember-source/@ember/-internals/views/lib/compat/fallback-view-registry");});
d("@ember/-internals/views/lib/component_lookup", function(){ return i("ember-source/@ember/-internals/views/lib/component_lookup");});
d("@ember/-internals/views/lib/mixins/action_support", function(){ return i("ember-source/@ember/-internals/views/lib/mixins/action_support");});
d("@ember/-internals/views/lib/mixins/child_views_support", function(){ return i("ember-source/@ember/-internals/views/lib/mixins/child_views_support");});
d("@ember/-internals/views/lib/mixins/class_names_support", function(){ return i("ember-source/@ember/-internals/views/lib/mixins/class_names_support");});
d("@ember/-internals/views/lib/mixins/view_state_support", function(){ return i("ember-source/@ember/-internals/views/lib/mixins/view_state_support");});
d("@ember/-internals/views/lib/mixins/view_support", function(){ return i("ember-source/@ember/-internals/views/lib/mixins/view_support");});
d("@ember/-internals/views/lib/system/action_manager", function(){ return i("ember-source/@ember/-internals/views/lib/system/action_manager");});
d("@ember/-internals/views/lib/system/event_dispatcher", function(){ return i("ember-source/@ember/-internals/views/lib/system/event_dispatcher");});
d("@ember/-internals/views/lib/system/utils", function(){ return i("ember-source/@ember/-internals/views/lib/system/utils");});
d("@ember/-internals/views/lib/views/core_view", function(){ return i("ember-source/@ember/-internals/views/lib/views/core_view");});
d("@ember/-internals/views/lib/views/states", function(){ return i("ember-source/@ember/-internals/views/lib/views/states");});
d("@ember/application/index", function(){ return i("ember-source/@ember/application/index");});
d("@ember/application/instance", function(){ return i("ember-source/@ember/application/instance");});
d("@ember/application/lib/lazy_load", function(){ return i("ember-source/@ember/application/lib/lazy_load");});
d("@ember/application/namespace", function(){ return i("ember-source/@ember/application/namespace");});
d("@ember/array/-internals", function(){ return i("ember-source/@ember/array/-internals");});
d("@ember/array/index", function(){ return i("ember-source/@ember/array/index");});
d("@ember/array/lib/make-array", function(){ return i("ember-source/@ember/array/lib/make-array");});
d("@ember/array/make", function(){ return i("ember-source/@ember/array/make");});
d("@ember/array/mutable", function(){ return i("ember-source/@ember/array/mutable");});
d("@ember/array/proxy", function(){ return i("ember-source/@ember/array/proxy");});
d("@ember/canary-features/index", function(){ return i("ember-source/@ember/canary-features/index");});
d("@ember/component/helper", function(){ return i("ember-source/@ember/component/helper");});
d("@ember/component/index", function(){ return i("ember-source/@ember/component/index");});
d("@ember/component/template-only", function(){ return i("ember-source/@ember/component/template-only");});
d("@ember/controller/index", function(){ return i("ember-source/@ember/controller/index");});
d("@ember/debug/container-debug-adapter", function(){ return i("ember-source/@ember/debug/container-debug-adapter");});
d("@ember/debug/data-adapter", function(){ return i("ember-source/@ember/debug/data-adapter");});
d("@ember/debug/index", function(){ return i("ember-source/@ember/debug/index");});
d("@ember/debug/lib/assert", function(){ return i("ember-source/@ember/debug/lib/assert");});
d("@ember/debug/lib/capture-render-tree", function(){ return i("ember-source/@ember/debug/lib/capture-render-tree");});
d("@ember/debug/lib/deprecate", function(){ return i("ember-source/@ember/debug/lib/deprecate");});
d("@ember/debug/lib/handlers", function(){ return i("ember-source/@ember/debug/lib/handlers");});
d("@ember/debug/lib/inspect", function(){ return i("ember-source/@ember/debug/lib/inspect");});
d("@ember/debug/lib/testing", function(){ return i("ember-source/@ember/debug/lib/testing");});
d("@ember/debug/lib/warn", function(){ return i("ember-source/@ember/debug/lib/warn");});
d("@ember/deprecated-features/index", function(){ return i("ember-source/@ember/deprecated-features/index");});
d("@ember/destroyable/index", function(){ return i("ember-source/@ember/destroyable/index");});
d("@ember/engine/index", function(){ return i("ember-source/@ember/engine/index");});
d("@ember/engine/instance", function(){ return i("ember-source/@ember/engine/instance");});
d("@ember/engine/lib/engine-parent", function(){ return i("ember-source/@ember/engine/lib/engine-parent");});
d("@ember/engine/parent", function(){ return i("ember-source/@ember/engine/parent");});
d("@ember/enumerable/index", function(){ return i("ember-source/@ember/enumerable/index");});
d("@ember/enumerable/mutable", function(){ return i("ember-source/@ember/enumerable/mutable");});
d("@ember/helper/index", function(){ return i("ember-source/@ember/helper/index");});
d("@ember/instrumentation/index", function(){ return i("ember-source/@ember/instrumentation/index");});
d("@ember/modifier/index", function(){ return i("ember-source/@ember/modifier/index");});
d("@ember/modifier/on", function(){ return i("ember-source/@ember/modifier/on");});
d("@ember/object/-internals", function(){ return i("ember-source/@ember/object/-internals");});
d("@ember/object/compat", function(){ return i("ember-source/@ember/object/compat");});
d("@ember/object/computed", function(){ return i("ember-source/@ember/object/computed");});
d("@ember/object/core", function(){ return i("ember-source/@ember/object/core");});
d("@ember/object/evented", function(){ return i("ember-source/@ember/object/evented");});
d("@ember/object/events", function(){ return i("ember-source/@ember/object/events");});
d("@ember/object/index", function(){ return i("ember-source/@ember/object/index");});
d("@ember/object/internals", function(){ return i("ember-source/@ember/object/internals");});
d("@ember/object/lib/computed/computed_macros", function(){ return i("ember-source/@ember/object/lib/computed/computed_macros");});
d("@ember/object/lib/computed/reduce_computed_macros", function(){ return i("ember-source/@ember/object/lib/computed/reduce_computed_macros");});
d("@ember/object/mixin", function(){ return i("ember-source/@ember/object/mixin");});
d("@ember/object/observable", function(){ return i("ember-source/@ember/object/observable");});
d("@ember/object/observers", function(){ return i("ember-source/@ember/object/observers");});
d("@ember/object/promise-proxy-mixin", function(){ return i("ember-source/@ember/object/promise-proxy-mixin");});
d("@ember/object/proxy", function(){ return i("ember-source/@ember/object/proxy");});
d("@ember/owner/index", function(){ return i("ember-source/@ember/owner/index");});
d("@ember/renderer/index", function(){ return i("ember-source/@ember/renderer/index");});
d("@ember/routing/-internals", function(){ return i("ember-source/@ember/routing/-internals");});
d("@ember/routing/hash-location", function(){ return i("ember-source/@ember/routing/hash-location");});
d("@ember/routing/history-location", function(){ return i("ember-source/@ember/routing/history-location");});
d("@ember/routing/index", function(){ return i("ember-source/@ember/routing/index");});
d("@ember/routing/lib/cache", function(){ return i("ember-source/@ember/routing/lib/cache");});
d("@ember/routing/lib/controller_for", function(){ return i("ember-source/@ember/routing/lib/controller_for");});
d("@ember/routing/lib/dsl", function(){ return i("ember-source/@ember/routing/lib/dsl");});
d("@ember/routing/lib/generate_controller", function(){ return i("ember-source/@ember/routing/lib/generate_controller");});
d("@ember/routing/lib/location-utils", function(){ return i("ember-source/@ember/routing/lib/location-utils");});
d("@ember/routing/lib/query_params", function(){ return i("ember-source/@ember/routing/lib/query_params");});
d("@ember/routing/lib/router_state", function(){ return i("ember-source/@ember/routing/lib/router_state");});
d("@ember/routing/lib/routing-service", function(){ return i("ember-source/@ember/routing/lib/routing-service");});
d("@ember/routing/lib/utils", function(){ return i("ember-source/@ember/routing/lib/utils");});
d("@ember/routing/none-location", function(){ return i("ember-source/@ember/routing/none-location");});
d("@ember/routing/route", function(){ return i("ember-source/@ember/routing/route");});
d("@ember/routing/router-service", function(){ return i("ember-source/@ember/routing/router-service");});
d("@ember/routing/router", function(){ return i("ember-source/@ember/routing/router");});
d("@ember/runloop/index", function(){ return i("ember-source/@ember/runloop/index");});
d("@ember/service/index", function(){ return i("ember-source/@ember/service/index");});
d("@ember/template-compilation/index", function(){ return i("ember-source/@ember/template-compilation/index");});
d("@ember/template-factory/index", function(){ return i("ember-source/@ember/template-factory/index");});
d("@ember/template/index", function(){ return i("ember-source/@ember/template/index");});
d("@ember/test/adapter", function(){ return i("ember-source/@ember/test/adapter");});
d("@ember/test/index", function(){ return i("ember-source/@ember/test/index");});
d("@ember/utils/index", function(){ return i("ember-source/@ember/utils/index");});
d("@ember/utils/lib/compare", function(){ return i("ember-source/@ember/utils/lib/compare");});
d("@ember/utils/lib/is-equal", function(){ return i("ember-source/@ember/utils/lib/is-equal");});
d("@ember/utils/lib/is_blank", function(){ return i("ember-source/@ember/utils/lib/is_blank");});
d("@ember/utils/lib/is_empty", function(){ return i("ember-source/@ember/utils/lib/is_empty");});
d("@ember/utils/lib/is_none", function(){ return i("ember-source/@ember/utils/lib/is_none");});
d("@ember/utils/lib/is_present", function(){ return i("ember-source/@ember/utils/lib/is_present");});
d("@ember/utils/lib/type-of", function(){ return i("ember-source/@ember/utils/lib/type-of");});
d("@ember/version/index", function(){ return i("ember-source/@ember/version/index");});
d("@glimmer/debug/index", function(){ return i("ember-source/@glimmer/debug/index");});
d("@glimmer/destroyable/index", function(){ return i("ember-source/@glimmer/destroyable/index");});
d("@glimmer/encoder/index", function(){ return i("ember-source/@glimmer/encoder/index");});
d("@glimmer/env/index", function(){ return i("ember-source/@glimmer/env/index");});
d("@glimmer/global-context/index", function(){ return i("ember-source/@glimmer/global-context/index");});
d("@glimmer/manager/index", function(){ return i("ember-source/@glimmer/manager/index");});
d("@glimmer/node/index", function(){ return i("ember-source/@glimmer/node/index");});
d("@glimmer/opcode-compiler/index", function(){ return i("ember-source/@glimmer/opcode-compiler/index");});
d("@glimmer/owner/index", function(){ return i("ember-source/@glimmer/owner/index");});
d("@glimmer/program/index", function(){ return i("ember-source/@glimmer/program/index");});
d("@glimmer/reference/index", function(){ return i("ember-source/@glimmer/reference/index");});
d("@glimmer/runtime/index", function(){ return i("ember-source/@glimmer/runtime/index");});
d("@glimmer/tracking/index", function(){ return i("ember-source/@glimmer/tracking/index");});
d("@glimmer/tracking/primitives/cache", function(){ return i("ember-source/@glimmer/tracking/primitives/cache");});
d("@glimmer/util/index", function(){ return i("ember-source/@glimmer/util/index");});
d("@glimmer/validator/index", function(){ return i("ember-source/@glimmer/validator/index");});
d("@glimmer/vm/index", function(){ return i("ember-source/@glimmer/vm/index");});
d("@glimmer/wire-format/index", function(){ return i("ember-source/@glimmer/wire-format/index");});
d("@simple-dom/document/index", function(){ return i("ember-source/@simple-dom/document/index");});
d("ember-source/backburner.js/", function(){ return i("ember-source/backburner.js/");});
d("backburner.js/index", function(){ return i("ember-source/backburner.js/index");});
d("dag-map/index", function(){ return i("ember-source/dag-map/index");});
d("ember-testing/index", function(){ return i("ember-source/ember-testing/index");});
d("ember-testing/lib/adapters/adapter", function(){ return i("ember-source/ember-testing/lib/adapters/adapter");});
d("ember-testing/lib/adapters/qunit", function(){ return i("ember-source/ember-testing/lib/adapters/qunit");});
d("ember-testing/lib/ext/application", function(){ return i("ember-source/ember-testing/lib/ext/application");});
d("ember-testing/lib/ext/rsvp", function(){ return i("ember-source/ember-testing/lib/ext/rsvp");});
d("ember-testing/lib/helpers", function(){ return i("ember-source/ember-testing/lib/helpers");});
d("ember-testing/lib/helpers/and_then", function(){ return i("ember-source/ember-testing/lib/helpers/and_then");});
d("ember-testing/lib/helpers/current_path", function(){ return i("ember-source/ember-testing/lib/helpers/current_path");});
d("ember-testing/lib/helpers/current_route_name", function(){ return i("ember-source/ember-testing/lib/helpers/current_route_name");});
d("ember-testing/lib/helpers/current_url", function(){ return i("ember-source/ember-testing/lib/helpers/current_url");});
d("ember-testing/lib/helpers/pause_test", function(){ return i("ember-source/ember-testing/lib/helpers/pause_test");});
d("ember-testing/lib/helpers/visit", function(){ return i("ember-source/ember-testing/lib/helpers/visit");});
d("ember-testing/lib/helpers/wait", function(){ return i("ember-source/ember-testing/lib/helpers/wait");});
d("ember-testing/lib/initializers", function(){ return i("ember-source/ember-testing/lib/initializers");});
d("ember-testing/lib/public-api", function(){ return i("ember-source/ember-testing/lib/public-api");});
d("ember-testing/lib/setup_for_testing", function(){ return i("ember-source/ember-testing/lib/setup_for_testing");});
d("ember-testing/lib/test", function(){ return i("ember-source/ember-testing/lib/test");});
d("ember-testing/lib/test/adapter", function(){ return i("ember-source/ember-testing/lib/test/adapter");});
d("ember-testing/lib/test/helpers", function(){ return i("ember-source/ember-testing/lib/test/helpers");});
d("ember-testing/lib/test/on_inject_helpers", function(){ return i("ember-source/ember-testing/lib/test/on_inject_helpers");});
d("ember-testing/lib/test/pending_requests", function(){ return i("ember-source/ember-testing/lib/test/pending_requests");});
d("ember-testing/lib/test/promise", function(){ return i("ember-source/ember-testing/lib/test/promise");});
d("ember-testing/lib/test/run", function(){ return i("ember-source/ember-testing/lib/test/run");});
d("ember-testing/lib/test/waiters", function(){ return i("ember-source/ember-testing/lib/test/waiters");});
d("ember/barrel", function(){ return i("ember-source/ember/barrel");});
d("ember/index", function(){ return i("ember-source/ember/index");});
d("ember/version", function(){ return i("ember-source/ember/version");});
d("route-recognizer/index", function(){ return i("ember-source/route-recognizer/index");});
d("router_js/index", function(){ return i("ember-source/router_js/index");});
d("rsvp/index", function(){ return i("ember-source/rsvp/index");});
d("shared-chunks/alias-Dri0koi2", function(){ return i("ember-source/shared-chunks/alias-Dri0koi2");});
d("shared-chunks/array-3xbmc_4J", function(){ return i("ember-source/shared-chunks/array-3xbmc_4J");});
d("shared-chunks/cache-BESCGvbE", function(){ return i("ember-source/shared-chunks/cache-BESCGvbE");});
d("shared-chunks/cache-qDyqAcpg", function(){ return i("ember-source/shared-chunks/cache-qDyqAcpg");});
d("shared-chunks/chunk-3SQBS3Y5-Cj4eryg1", function(){ return i("ember-source/shared-chunks/chunk-3SQBS3Y5-Cj4eryg1");});
d("shared-chunks/computed_cache-DmYKevAP", function(){ return i("ember-source/shared-chunks/computed_cache-DmYKevAP");});
d("shared-chunks/dictionary-gc5gpyOG", function(){ return i("ember-source/shared-chunks/dictionary-gc5gpyOG");});
d("shared-chunks/env-BJLX2Arx", function(){ return i("ember-source/shared-chunks/env-BJLX2Arx");});
d("shared-chunks/index-BGP1rw3B", function(){ return i("ember-source/shared-chunks/index-BGP1rw3B");});
d("shared-chunks/index-Cx6axmzR", function(){ return i("ember-source/shared-chunks/index-Cx6axmzR");});
d("shared-chunks/invoke-BjRgvK2V", function(){ return i("ember-source/shared-chunks/invoke-BjRgvK2V");});
d("shared-chunks/is_proxy-DjvCKvd5", function(){ return i("ember-source/shared-chunks/is_proxy-DjvCKvd5");});
d("shared-chunks/managers-CbMgnSoo", function(){ return i("ember-source/shared-chunks/managers-CbMgnSoo");});
d("shared-chunks/mandatory-setter-BiXq-dpN", function(){ return i("ember-source/shared-chunks/mandatory-setter-BiXq-dpN");});
d("shared-chunks/name-Dx2bGFVv", function(){ return i("ember-source/shared-chunks/name-Dx2bGFVv");});
d("shared-chunks/namespace_search-btMaPM-_", function(){ return i("ember-source/shared-chunks/namespace_search-btMaPM-_");});
d("shared-chunks/property_set-BapAkp3X", function(){ return i("ember-source/shared-chunks/property_set-BapAkp3X");});
d("shared-chunks/public-api-BQsJemZG", function(){ return i("ember-source/shared-chunks/public-api-BQsJemZG");});
d("shared-chunks/registry-B8WARvkP", function(){ return i("ember-source/shared-chunks/registry-B8WARvkP");});
d("shared-chunks/router-DrLZsJeE", function(){ return i("ember-source/shared-chunks/router-DrLZsJeE");});
d("shared-chunks/rsvp-DaQAFb0W", function(){ return i("ember-source/shared-chunks/rsvp-DaQAFb0W");});
d("shared-chunks/set_properties-BScfxzvI", function(){ return i("ember-source/shared-chunks/set_properties-BScfxzvI");});
d("shared-chunks/setup-registry-CEsz7P_1", function(){ return i("ember-source/shared-chunks/setup-registry-CEsz7P_1");});
d("shared-chunks/template_registry-DigcUg9m", function(){ return i("ember-source/shared-chunks/template_registry-DigcUg9m");});
d("shared-chunks/to-string-B1BmwUkt", function(){ return i("ember-source/shared-chunks/to-string-B1BmwUkt");});
d("shared-chunks/unrecognized-url-error-zpz-JEoG", function(){ return i("ember-source/shared-chunks/unrecognized-url-error-zpz-JEoG");});
d("ember/index", function(){ return i("ember-source/ember/index.js");});
d("ember-on-resize-modifier/modifiers/on-resize", function(){ return i("ember-on-resize-modifier/modifiers/on-resize");});
d("float-kit/components/d-button-tooltip", function(){ return i("float-kit/components/d-button-tooltip");});
d("float-kit/components/d-default-toast", function(){ return i("float-kit/components/d-default-toast");});
d("float-kit/components/d-float-body", function(){ return i("float-kit/components/d-float-body");});
d("float-kit/components/d-float-portal", function(){ return i("float-kit/components/d-float-portal");});
d("float-kit/components/d-headless-menu", function(){ return i("float-kit/components/d-headless-menu");});
d("float-kit/components/d-headless-tooltip", function(){ return i("float-kit/components/d-headless-tooltip");});
d("float-kit/components/d-inline-float", function(){ return i("float-kit/components/d-inline-float");});
d("float-kit/components/d-menu", function(){ return i("float-kit/components/d-menu");});
d("float-kit/components/d-menus", function(){ return i("float-kit/components/d-menus");});
d("float-kit/components/d-popover", function(){ return i("float-kit/components/d-popover");});
d("float-kit/components/d-toast", function(){ return i("float-kit/components/d-toast");});
d("float-kit/components/d-toasts", function(){ return i("float-kit/components/d-toasts");});
d("float-kit/components/d-tooltip", function(){ return i("float-kit/components/d-tooltip");});
d("float-kit/components/d-tooltips", function(){ return i("float-kit/components/d-tooltips");});
d("float-kit/lib/constants", function(){ return i("float-kit/lib/constants");});
d("float-kit/lib/d-menu-instance", function(){ return i("float-kit/lib/d-menu-instance");});
d("float-kit/lib/d-toast-instance", function(){ return i("float-kit/lib/d-toast-instance");});
d("float-kit/lib/d-tooltip-instance", function(){ return i("float-kit/lib/d-tooltip-instance");});
d("float-kit/lib/float-kit-instance", function(){ return i("float-kit/lib/float-kit-instance");});
d("float-kit/lib/get-scroll-parent", function(){ return i("float-kit/lib/get-scroll-parent");});
d("float-kit/lib/update-position", function(){ return i("float-kit/lib/update-position");});
d("float-kit/modifiers/apply-floating-ui", function(){ return i("float-kit/modifiers/apply-floating-ui");});
d("float-kit/modifiers/auto-close-toast", function(){ return i("float-kit/modifiers/auto-close-toast");});
d("float-kit/modifiers/close-on-escape", function(){ return i("float-kit/modifiers/close-on-escape");});
d("float-kit/services/internal-tooltip", function(){ return i("float-kit/services/internal-tooltip");});
d("float-kit/services/menu", function(){ return i("float-kit/services/menu");});
d("float-kit/services/toasts", function(){ return i("float-kit/services/toasts");});
d("float-kit/services/tooltip", function(){ return i("float-kit/services/tooltip");});
d("select-kit/components/bulk-select-bookmarks-dropdown", function(){ return i("select-kit/components/bulk-select-bookmarks-dropdown");});
d("select-kit/components/categories-admin-dropdown", function(){ return i("select-kit/components/categories-admin-dropdown");});
d("select-kit/components/category-chooser", function(){ return i("select-kit/components/category-chooser");});
d("select-kit/components/category-drop-more-collection", function(){ return i("select-kit/components/category-drop-more-collection");});
d("select-kit/components/category-drop", function(){ return i("select-kit/components/category-drop");});
d("select-kit/components/category-drop/category-drop-header", function(){ return i("select-kit/components/category-drop/category-drop-header");});
d("select-kit/components/category-row", function(){ return i("select-kit/components/category-row");});
d("select-kit/components/category-selector", function(){ return i("select-kit/components/category-selector");});
d("select-kit/components/color-palettes", function(){ return i("select-kit/components/color-palettes");});
d("select-kit/components/color-palettes/color-palettes-row", function(){ return i("select-kit/components/color-palettes/color-palettes-row");});
d("select-kit/components/combo-box", function(){ return i("select-kit/components/combo-box");});
d("select-kit/components/combo-box/combo-box-header", function(){ return i("select-kit/components/combo-box/combo-box-header");});
d("select-kit/components/composer-actions", function(){ return i("select-kit/components/composer-actions");});
d("select-kit/components/create-color-row", function(){ return i("select-kit/components/create-color-row");});
d("select-kit/components/dropdown-select-box", function(){ return i("select-kit/components/dropdown-select-box");});
d("select-kit/components/dropdown-select-box/dropdown-select-box-header", function(){ return i("select-kit/components/dropdown-select-box/dropdown-select-box-header");});
d("select-kit/components/dropdown-select-box/dropdown-select-box-row", function(){ return i("select-kit/components/dropdown-select-box/dropdown-select-box-row");});
d("select-kit/components/email-group-user-chooser-filter", function(){ return i("select-kit/components/email-group-user-chooser-filter");});
d("select-kit/components/email-group-user-chooser-row", function(){ return i("select-kit/components/email-group-user-chooser-row");});
d("select-kit/components/email-group-user-chooser", function(){ return i("select-kit/components/email-group-user-chooser");});
d("select-kit/components/filter-for-more", function(){ return i("select-kit/components/filter-for-more");});
d("select-kit/components/flair-chooser", function(){ return i("select-kit/components/flair-chooser");});
d("select-kit/components/flair-row", function(){ return i("select-kit/components/flair-row");});
d("select-kit/components/form-template-chooser", function(){ return i("select-kit/components/form-template-chooser");});
d("select-kit/components/future-date-input-selector", function(){ return i("select-kit/components/future-date-input-selector");});
d("select-kit/components/future-date-input-selector/future-date-input-selector-header", function(){ return i("select-kit/components/future-date-input-selector/future-date-input-selector-header");});
d("select-kit/components/future-date-input-selector/future-date-input-selector-row", function(){ return i("select-kit/components/future-date-input-selector/future-date-input-selector-row");});
d("select-kit/components/group-chooser", function(){ return i("select-kit/components/group-chooser");});
d("select-kit/components/group-dropdown", function(){ return i("select-kit/components/group-dropdown");});
d("select-kit/components/group-notifications-button", function(){ return i("select-kit/components/group-notifications-button");});
d("select-kit/components/icon-picker", function(){ return i("select-kit/components/icon-picker");});
d("select-kit/components/list-setting", function(){ return i("select-kit/components/list-setting");});
d("select-kit/components/mini-tag-chooser", function(){ return i("select-kit/components/mini-tag-chooser");});
d("select-kit/components/mini-tag-chooser/selected-collection", function(){ return i("select-kit/components/mini-tag-chooser/selected-collection");});
d("select-kit/components/multi-select", function(){ return i("select-kit/components/multi-select");});
d("select-kit/components/multi-select/format-selected-content", function(){ return i("select-kit/components/multi-select/format-selected-content");});
d("select-kit/components/multi-select/multi-select-filter", function(){ return i("select-kit/components/multi-select/multi-select-filter");});
d("select-kit/components/multi-select/multi-select-header", function(){ return i("select-kit/components/multi-select/multi-select-header");});
d("select-kit/components/multi-select/selected-category", function(){ return i("select-kit/components/multi-select/selected-category");});
d("select-kit/components/multi-select/selected-color", function(){ return i("select-kit/components/multi-select/selected-color");});
d("select-kit/components/none-category-row", function(){ return i("select-kit/components/none-category-row");});
d("select-kit/components/notifications-button", function(){ return i("select-kit/components/notifications-button");});
d("select-kit/components/notifications-button/notifications-button-row", function(){ return i("select-kit/components/notifications-button/notifications-button-row");});
d("select-kit/components/notifications-filter", function(){ return i("select-kit/components/notifications-filter");});
d("select-kit/components/notifications-filter/notifications-filter-header", function(){ return i("select-kit/components/notifications-filter/notifications-filter-header");});
d("select-kit/components/period-chooser", function(){ return i("select-kit/components/period-chooser");});
d("select-kit/components/period-chooser/period-chooser-header", function(){ return i("select-kit/components/period-chooser/period-chooser-header");});
d("select-kit/components/period-chooser/period-chooser-row", function(){ return i("select-kit/components/period-chooser/period-chooser-row");});
d("select-kit/components/pinned-button", function(){ return i("select-kit/components/pinned-button");});
d("select-kit/components/pinned-options", function(){ return i("select-kit/components/pinned-options");});
d("select-kit/components/search-advanced-category-chooser", function(){ return i("select-kit/components/search-advanced-category-chooser");});
d("select-kit/components/select-kit", function(){ return i("select-kit/components/select-kit");});
d("select-kit/components/select-kit/errors-collection", function(){ return i("select-kit/components/select-kit/errors-collection");});
d("select-kit/components/select-kit/select-kit-body", function(){ return i("select-kit/components/select-kit/select-kit-body");});
d("select-kit/components/select-kit/select-kit-collection", function(){ return i("select-kit/components/select-kit/select-kit-collection");});
d("select-kit/components/select-kit/select-kit-create-row", function(){ return i("select-kit/components/select-kit/select-kit-create-row");});
d("select-kit/components/select-kit/select-kit-filter", function(){ return i("select-kit/components/select-kit/select-kit-filter");});
d("select-kit/components/select-kit/select-kit-header", function(){ return i("select-kit/components/select-kit/select-kit-header");});
d("select-kit/components/select-kit/select-kit-none-row", function(){ return i("select-kit/components/select-kit/select-kit-none-row");});
d("select-kit/components/select-kit/select-kit-row", function(){ return i("select-kit/components/select-kit/select-kit-row");});
d("select-kit/components/select-kit/single-select-header", function(){ return i("select-kit/components/select-kit/single-select-header");});
d("select-kit/components/selected-choice-category", function(){ return i("select-kit/components/selected-choice-category");});
d("select-kit/components/selected-choice-color", function(){ return i("select-kit/components/selected-choice-color");});
d("select-kit/components/selected-choice", function(){ return i("select-kit/components/selected-choice");});
d("select-kit/components/selected-color", function(){ return i("select-kit/components/selected-color");});
d("select-kit/components/selected-flair", function(){ return i("select-kit/components/selected-flair");});
d("select-kit/components/selected-name", function(){ return i("select-kit/components/selected-name");});
d("select-kit/components/single-select", function(){ return i("select-kit/components/single-select");});
d("select-kit/components/tag-chooser-row", function(){ return i("select-kit/components/tag-chooser-row");});
d("select-kit/components/tag-chooser", function(){ return i("select-kit/components/tag-chooser");});
d("select-kit/components/tag-drop", function(){ return i("select-kit/components/tag-drop");});
d("select-kit/components/tag-drop/tag-drop-header", function(){ return i("select-kit/components/tag-drop/tag-drop-header");});
d("select-kit/components/tag-group-chooser", function(){ return i("select-kit/components/tag-group-chooser");});
d("select-kit/components/tag-notifications-button", function(){ return i("select-kit/components/tag-notifications-button");});
d("select-kit/components/tag-row", function(){ return i("select-kit/components/tag-row");});
d("select-kit/components/tags-intersection-chooser", function(){ return i("select-kit/components/tags-intersection-chooser");});
d("select-kit/components/timezone-input", function(){ return i("select-kit/components/timezone-input");});
d("select-kit/components/toolbar-popup-menu-options", function(){ return i("select-kit/components/toolbar-popup-menu-options");});
d("select-kit/components/toolbar-popup-menu-options/toolbar-popup-menu-options-heading", function(){ return i("select-kit/components/toolbar-popup-menu-options/toolbar-popup-menu-options-heading");});
d("select-kit/components/topic-chooser", function(){ return i("select-kit/components/topic-chooser");});
d("select-kit/components/topic-notifications-button", function(){ return i("select-kit/components/topic-notifications-button");});
d("select-kit/components/topic-notifications-options", function(){ return i("select-kit/components/topic-notifications-options");});
d("select-kit/components/topic-row", function(){ return i("select-kit/components/topic-row");});
d("select-kit/components/user-chooser", function(){ return i("select-kit/components/user-chooser");});
d("select-kit/components/user-chooser/user-row", function(){ return i("select-kit/components/user-chooser/user-row");});
d("select-kit/components/user-notifications-dropdown", function(){ return i("select-kit/components/user-notifications-dropdown");});
d("select-kit/components/watched-words", function(){ return i("select-kit/components/watched-words");});
d("select-kit/mixins/plugin-api", function(){ return i("select-kit/mixins/plugin-api");});
d("select-kit/mixins/tags", function(){ return i("select-kit/mixins/tags");});
d("select-kit/mixins/utils", function(){ return i("select-kit/mixins/utils");});
d("select-kit/components/category-drop/category-drop-header", function(){ return i("select-kit/components/category-drop/category-drop-header");});
d("select-kit/components/color-palettes/color-palettes-row", function(){ return i("select-kit/components/color-palettes/color-palettes-row");});
d("select-kit/components/combo-box/combo-box-header", function(){ return i("select-kit/components/combo-box/combo-box-header");});
d("select-kit/components/create-color-row", function(){ return i("select-kit/components/create-color-row");});
d("select-kit/components/dropdown-select-box/dropdown-select-box-header", function(){ return i("select-kit/components/dropdown-select-box/dropdown-select-box-header");});
d("select-kit/components/dropdown-select-box/dropdown-select-box-row", function(){ return i("select-kit/components/dropdown-select-box/dropdown-select-box-row");});
d("select-kit/components/email-group-user-chooser-row", function(){ return i("select-kit/components/email-group-user-chooser-row");});
d("select-kit/components/flair-row", function(){ return i("select-kit/components/flair-row");});
d("select-kit/components/future-date-input-selector/future-date-input-selector-header", function(){ return i("select-kit/components/future-date-input-selector/future-date-input-selector-header");});
d("select-kit/components/future-date-input-selector/future-date-input-selector-row", function(){ return i("select-kit/components/future-date-input-selector/future-date-input-selector-row");});
d("select-kit/components/mini-tag-chooser/selected-collection", function(){ return i("select-kit/components/mini-tag-chooser/selected-collection");});
d("select-kit/components/multi-select", function(){ return i("select-kit/components/multi-select");});
d("select-kit/components/multi-select/format-selected-content", function(){ return i("select-kit/components/multi-select/format-selected-content");});
d("select-kit/components/multi-select/multi-select-filter", function(){ return i("select-kit/components/multi-select/multi-select-filter");});
d("select-kit/components/multi-select/multi-select-header", function(){ return i("select-kit/components/multi-select/multi-select-header");});
d("select-kit/components/multi-select/selected-category", function(){ return i("select-kit/components/multi-select/selected-category");});
d("select-kit/components/none-category-row", function(){ return i("select-kit/components/none-category-row");});
d("select-kit/components/notifications-filter/notifications-filter-header", function(){ return i("select-kit/components/notifications-filter/notifications-filter-header");});
d("select-kit/components/period-chooser/period-chooser-header", function(){ return i("select-kit/components/period-chooser/period-chooser-header");});
d("select-kit/components/period-chooser/period-chooser-row", function(){ return i("select-kit/components/period-chooser/period-chooser-row");});
d("select-kit/components/pinned-button", function(){ return i("select-kit/components/pinned-button");});
d("select-kit/components/select-kit/errors-collection", function(){ return i("select-kit/components/select-kit/errors-collection");});
d("select-kit/components/select-kit/select-kit-body", function(){ return i("select-kit/components/select-kit/select-kit-body");});
d("select-kit/components/select-kit/select-kit-collection", function(){ return i("select-kit/components/select-kit/select-kit-collection");});
d("select-kit/components/select-kit/select-kit-create-row", function(){ return i("select-kit/components/select-kit/select-kit-create-row");});
d("select-kit/components/select-kit/select-kit-filter", function(){ return i("select-kit/components/select-kit/select-kit-filter");});
d("select-kit/components/select-kit/select-kit-none-row", function(){ return i("select-kit/components/select-kit/select-kit-none-row");});
d("select-kit/components/select-kit/select-kit-row", function(){ return i("select-kit/components/select-kit/select-kit-row");});
d("select-kit/components/select-kit/single-select-header", function(){ return i("select-kit/components/select-kit/single-select-header");});
d("select-kit/components/selected-choice-category", function(){ return i("select-kit/components/selected-choice-category");});
d("select-kit/components/selected-choice", function(){ return i("select-kit/components/selected-choice");});
d("select-kit/components/selected-flair", function(){ return i("select-kit/components/selected-flair");});
d("select-kit/components/selected-name", function(){ return i("select-kit/components/selected-name");});
d("select-kit/components/single-select", function(){ return i("select-kit/components/single-select");});
d("select-kit/components/tag-chooser-row", function(){ return i("select-kit/components/tag-chooser-row");});
d("select-kit/components/tag-drop/tag-drop-header", function(){ return i("select-kit/components/tag-drop/tag-drop-header");});
d("select-kit/components/tag-row", function(){ return i("select-kit/components/tag-row");});
d("select-kit/components/toolbar-popup-menu-options/toolbar-popup-menu-options-heading", function(){ return i("select-kit/components/toolbar-popup-menu-options/toolbar-popup-menu-options-heading");});
d("select-kit/components/topic-row", function(){ return i("select-kit/components/topic-row");});
d("select-kit/components/user-chooser/user-row", function(){ return i("select-kit/components/user-chooser/user-row");});
import "ember-route-template/-embroider-implicit-modules.js";
import "@ember-compat/tracked-built-ins/-embroider-implicit-modules.js";
import "@ember/test-helpers/-embroider-implicit-modules.js";
import "@ember/test-waiters/-embroider-implicit-modules.js";
import "@embroider/router/-embroider-implicit-modules.js";
import "discourse-i18n/-embroider-implicit-modules.js";
import "discourse-markdown-it/-embroider-implicit-modules.js";
import "ember-cli-babel/-embroider-implicit-modules.js";
import "ember-modifier/-embroider-implicit-modules.js";
import "ember-qunit/-embroider-implicit-modules.js";
import "truth-helpers/-embroider-implicit-modules.js";
import "ember-cli-inject-live-reload/-embroider-implicit-modules.js";
import "custom-proxy/-embroider-implicit-modules.js";
import "ember-tracked-storage-polyfill/-embroider-implicit-modules.js";
import "@embroider/macros/-embroider-implicit-modules.js";
import "@ember/legacy-built-in-components/-embroider-implicit-modules.js";
import "@ember/optional-features/-embroider-implicit-modules.js";
import "@ember/render-modifiers/-embroider-implicit-modules.js";
import "@glimmer/tracking/-embroider-implicit-modules.js";
import "ember-cached-decorator-polyfill/-embroider-implicit-modules.js";
import "ember-template-imports/-embroider-implicit-modules.js";
import "ember-auto-import/-embroider-implicit-modules.js";
import "@glimmer/component/-embroider-implicit-modules.js";
import "ember-cli-htmlbars/-embroider-implicit-modules.js";
import "discourse-common/-embroider-implicit-modules.js";
import "admin/-embroider-implicit-modules.js";
import "dialog-holder/-embroider-implicit-modules.js";
import "pretty-text/-embroider-implicit-modules.js";
import "discourse-widget-hbs/-embroider-implicit-modules.js";
import "discourse-plugins/-embroider-implicit-modules.js";
import "ember-buffered-proxy/-embroider-implicit-modules.js";
import "ember-cli-app-version/-embroider-implicit-modules.js";
import "ember-test-selectors/-embroider-implicit-modules.js";
import "ember-cli-deprecation-workflow/-embroider-implicit-modules.js";
import "ember-cli-progress-ci/-embroider-implicit-modules.js";
import "broccoli-asset-rev/-embroider-implicit-modules.js";
import "ember-cli-terser/-embroider-implicit-modules.js";
import "ember-cli-sri/-embroider-implicit-modules.js";
import "ember-source/-embroider-implicit-modules.js";
import "ember-decorators/-embroider-implicit-modules.js";
import "ember-exam/-embroider-implicit-modules.js";
import "ember-on-resize-modifier/-embroider-implicit-modules.js";
import "float-kit/-embroider-implicit-modules.js";
import "loader.js/-embroider-implicit-modules.js";
import "select-kit/-embroider-implicit-modules.js";
